@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
}

body {
  background: #e7e7e7;
}

input {
  padding: 1rem !important;
}
